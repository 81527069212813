* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
}

body {
  background-color: #020511;
  color: #ffffff;
  overflow: hidden;
}